/* eslint-disable react/no-danger */
import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import HeroSingle from '../components/pages/HeroSingle';
import LineFull from '../images/linefull.jpg';
import Greenlogo from '../images/greenlogo.jpg';
import SEO from '../components/seo';
import '../styles/app.scss';
import '../styles/_theme_fix_pages.css';

const hotdeals = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <SEO
                title={new_seo_title}
                meta={post.yoast_meta}
                description={
                    post.yoast_json_ld[0].wordpress__graph[0].description
                }
            />
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />

            <style>
                {`
             .all-popup {
display: !important;
    visibility: hidden;
    z-index: -2!important;
             } 
        `}
            </style>

            <section className="page-section smallestwdt notbottompaddsec nopaddbottsec">

                <div className="container">


                    <div
                        className="openprgt middle-text"
                        dangerouslySetInnerHTML={{
                            __html: post.acf.open_play_content_right,
                        }}
                    />
                    <h3 className='big-title_deals'>Limited-Time Specials (Nov 29 - Jan 3)</h3>
                    <div className="clearall"></div>
                </div>
            </section>

            <div className="container text_center mt-15">
                <img src={LineFull} alt="linefull" loading="lazy" />
            </div>
            <div 
                className="elfsight-app-1d08c6c8-36bd-4de7-9012-906a6895ddc1" 
                data-elfsight-app-lazy
                style={{
                    paddingTop: '40px',
                    paddingBottom: '40px',
                    }}
            ></div>

     {/*       <section className="page-section openphours">
                <div className="container smallestwdt">
                    <div className="twothirdcol display_none">

                    </div>
                    <p className='titles_for-Heal'><span className='bluetxt'>Birthdays</span> <br /><br />
                        <span className='bluetxt'>$25 Gift Certificate with Every Booking</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                        {post.acf.open_play_register_widget_codes.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code,
                                    }}
                                />
                            )
                        )}

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Open Play Passes</span>
                        <br />
                        <br />
                        <span className='bluetxt'>BUY 3 GET 1 FREE</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                        {post.acf.open_play_register_widget_codes_thanksgiving.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_thanksgiving,
                                    }}
                                />
                            )
                        )}

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <div className='hot_deal_healcode_wrap'>
                        <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Camps
                            <br />
                            <br />
                            Early Bird Pricing</span> </p>
                        <p className="hd-info">$55 per Day for Multi-Day Camps <br />
                            $80 per Day for Multi-Day Camps with After-Care</p>
                        <a className='healcode-pricing-option-text-link wrtsbtn yellowbtn healcode-register' href="/camp/">Sign Up</a>
                        </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Drop Off Care</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                        {post.acf.open_play_register_widget_codes_dropoff.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_dropoff,
                                    }}
                                />
                            )
                        )}

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Monthly Class Pass		
		


                        <br /><br />
                        Unlimited Classes for 1 Month</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                        {post.acf.open_play_register_widget_codes_mclasspass.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_mclasspass,
                                    }}
                                />
                            )
                        )}

                    </div>
                    <div className="container text_center mt-15">
                        <img src={LineFull} alt="linefull" loading="lazy" />
                    </div>
                    <p className='titles_for-Heal sec_heal_title'><span className='bluetxt'>Open Play Monthly Rockstar Membership

                        <br /><br />
                        Unlimited 2 Hour Drop-in's for Open Play each Month
                        <br />
                        Valid for 4 Months
                        <br />
                        1 Guest Pass Every Month</span>
                        <br />
                        <br />
                        <span className='bluetxt'>BUY 3 GET 1 FREE</span>
                    </p>
                    <div className="onethirdcol full-row_heals hot_deal_healcode">

                        {post.acf.open_play_register_widget_codes_copy.map(
                            (scheduleCode, i) => (
                                <div
                                    className="healwrap"
                                    key={`key_${i}`}
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            scheduleCode.open_play_register_widget_code_right,
                                    }}
                                />
                            )
                        )}

                    </div>

                    <div className='new_reg_place display_none'>
                        <a
                            href="/"
                            style={{ background: 'none', padding: 0 }}
                        >

                        </a>
                    </div>

                </div>
            </section>
		<div className="popup-wrap se_popup_pno">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/se_parents_one.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
		<div className="popup-wrap se_popup_pno_two">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/se_parents_two.php" title="appointment" className="healcode_widgets" />

                </div>
            </div>
	<div className="popup-wrap se_popup_pno_three">
                <div className="popup">
                    <span className="close_popup">×</span>

                    <iframe src="https://werockthespectrumatlantagym.wrtsfranchise.com/se_parents_three.php" title="appointment" className="healcode_widgets" />

                </div>
            </div> 


            <section className="page-section bg-secondary text-white centersec">
<div className="container smallestwdt">

<h5>MONDAY - SATURDAY: 9AM - 6PM</h5>
<h5>SUNDAY: 10AM - 4:30PM</h5>

</div>
</section> 


                    */}

            <Footer />
        </>
    );
};

export default hotdeals;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            acf {
                open_play_content_right
                open_play_content_second
                open_play_register_widget_codes {
                    open_play_register_widget_code
                }
                open_play_register_widget_codes_thanksgiving {
                    open_play_register_widget_code_thanksgiving
                }
                open_play_register_widget_codes_dropoff {
                    open_play_register_widget_code_dropoff
                }
                open_play_register_widget_codes_mclasspass {
                    open_play_register_widget_code_mclasspass
                }
                open_play_register_widget_codes_copy {
                    open_play_register_widget_code_right
                }
            }
            yoast_meta {
                name
                property
            }
            yoast_title
            yoast_json_ld {
                wordpress__graph {
                    description
                }
            }
        }
    }
`;
